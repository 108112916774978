import React from 'react';
import {
    Box,
    Flex,
    Container, Button,
} from 'theme-ui';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Link from "gatsby-link";

const NotFoundPage = () => (
  <Layout>
    <SEO />
    <Box as="section" id="notfound" sx={styles.notFound}>
        <Container sx={styles.notFound.container}>
            <Flex sx={styles.notFound.row}>
                <Box sx={styles.notFound.col}>
                    <h1>Diese Seite existiert leider nicht.</h1>
                    <Link to={'/'}>
                        <Button sx={styles.notFound.button}>
                            Zurück zur Startseite
                        </Button>
                    </Link>
                </Box>
            </Flex>
        </Container>
    </Box>
  </Layout>
);

const styles = {
    notFound: {
        overflowX: 'hidden',
        paddingTop: ['100px', '120px'],
        paddingBottom: ['0px', null, '80px'],
        container: {
            maxWidth: ['100%', null, null, null, null, '1240px', '1440px'],
        },
        row: {
            flexWrap: 'wrap',
            display: 'flex',
            marginLeft: '-15px',
            marginRight: '-15px',
        },
        col: {
            pl: '15px',
            pr: '15px',
            flex: ['1 1 100%', null, '0 0 50%'],
        },
        button: {
            outline: 'none',
            display: 'inline-block',
            fontWeight: 'bold',
            color: 'primary',
            bg: 'primaryLight',
            cursor: 'pointer',
            transition: '500ms',
            fontSize: ['14px', null, null, '16px'],
            letterSpacing: '-0.16px',
            marginTop: 3,
            marginBottom: [4, 4, 0, 0],
            padding: '10px 19px',
            borderRadius: '5px',
            textDecoration: 'none',
            '&:hover': {
                bg: 'primary',
                color: '#fff',
            },
        },
    },
};

export default NotFoundPage;
